import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const BremsfluessigkeitswechselPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Bremsflüssigkeitswechsel</h1>

                <div className={globalStyle.text_container}>
                    <p>Der Siedepunkt einer neuen Bremsflüssigkeit liegt bei ca. 230 Grad Celsius.
                        Bereits im Verlaufe eines Jahres nimmt die Flüssigkeit bis zu 2% Wasser auf, wodurch der
                        Siedepunkt
                        um bis zu 60 Grad Celsius sinkt.</p>

                    <p>Für eine zuverlässige und sichere Funktion der Bremsanlage schreiben die Fahrzeughersteller daher
                        den
                        regelmäßigen Bremsflüssigkeitswechsel vor (häufig alle 2 Jahre).</p>
                </div>
            </div>


            <Footer/>
        </>
    )
}