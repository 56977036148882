import styles from './style.module.scss';
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import {useState} from "react";
import {IJob} from "../../entities/job";
import jobs from '../../assets/data/jobs.json';
import {JobDetail} from "../../components/jobs/job-detail";
import {JobList} from "../../components/jobs/job-list";

export const Jobs = () => {
    const [selectedJob, setSelectedJob] = useState<IJob | undefined>();

    const unselectJob = () => {
        setSelectedJob(undefined);
    }

    return (
        <div>
            <Header/>

            <div className={styles.job_section}>
                <h1 className={styles.section_title}>Alle <span>Jobs</span></h1>

                <div className={styles.mobile_header}>
                    {
                        !selectedJob ? (
                            <h1 className={styles.mobile_title}>
                                Alle <span>Jobs</span>
                            </h1>
                        ) : (
                            <button className={styles.mobile_back_button} onClick={unselectJob}>
                                <span>Zurück zu allen Jobs</span>
                            </button>
                        )
                    }
                </div>

                <div className={styles.app_container}>
                    <JobList jobs={jobs} onJobSelect={setSelectedJob}/>
                    {selectedJob && <JobDetail job={selectedJob}/>}
                </div>

                <div className={styles.mobile_app_container}>
                    {
                        selectedJob ?
                            <JobDetail job={selectedJob}/> :
                            <JobList jobs={jobs} onJobSelect={setSelectedJob}/>
                    }
                </div>
            </div>

            <Footer/>
        </div>
    )
}