import {useEffect, useState} from "react";
import {INews} from "../../../entities/news";
import {useNavigate} from "react-router-dom";
import newsData from "../../../assets/data/news.json";
import styles from "./style.module.scss";
import newsImg
    from "../../../assets/images/news/i-want-a-image-where-you-can-see-a-winter-tire-from-a-car-in-the-snow-and-a-part-of-a-car.jpeg";

export const FullNewsPage = ({postTitle}: { postTitle: string }) => {
    const [news, setNews] = useState<INews | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
        const news = newsData.find((n: INews) => n.active && n.title.toLowerCase() === postTitle.toLowerCase());
        setNews(news);
    }, [postTitle]);

    return (
        <div className={styles.full_news_container}>
            {
                news && (
                    <div className={styles.post}>
                        <div className={styles.header}>
                            <button className={styles.more_button} onClick={() => navigate("/neuigkeiten")}>
                                Zurück
                            </button>

                            <div className={styles.info}>
                                <span className={styles.category}>{news.category}</span>
                                <span className={styles.date}>{news.date}</span>
                            </div>
                        </div>

                        <div className={styles.main_post}>
                            <img src={newsImg} alt="news" className={styles.image}/>
                            <div className={styles.content}>
                                <h2 className={styles.title}>{news.title}</h2>
                                <p className={styles.description} dangerouslySetInnerHTML={{__html: news.description.replace(/\n\n/g, '<br /><br />')}}/>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
}
