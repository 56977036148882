import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Home} from "./pages/home";
import {Impressum} from "./pages/impressum";
import {Datenschutz} from "./pages/datenschutz";
import {Services} from "./pages/services";
import {Contact} from "./pages/contact";
import {TeamPage} from "./pages/team";
import {AboutUsPage} from "./pages/about-us";
import {AchsvermessungPage} from "./pages/services/sub-pages/achsvermssung";
import {AuspuffservicePage} from "./pages/services/sub-pages/auspuffservice";
import {BremsfluessigkeitswechselPage} from "./pages/services/sub-pages/bremsflüssigkeitswechsel";
import {ElektroautoServicePage} from "./pages/services/sub-pages/elektroauto-service";
import {FahrzeugDiagnosePage} from "./pages/services/sub-pages/fahrzeug-diagnose";
import {GetriebespuelungPage} from "./pages/services/sub-pages/getriebespülung";
import {HauptuntersuchungAbgasinspektionPage} from "./pages/services/sub-pages/hauptuntersuchung-abgasinspektion";
import {HolUndBringdienstPage} from "./pages/services/sub-pages/hol-und-bringdienst";
import {KfzReperaturnAllerFabrikatePage} from "./pages/services/sub-pages/kfz-reperaturn-aller-fabrikate";
import {KlimaserviceKlimaanlagenPage} from "./pages/services/sub-pages/klimaservice-klimaanlagen";
import {ReifenservicePage} from "./pages/services/sub-pages/reifenservice";
import {UmweltplakettePage} from "./pages/services/sub-pages/umweltplakette";
import {UnfallinstandsetungPage} from "./pages/services/sub-pages/unfallinstandsetung";
import {VerkaufsvermittlungPage} from "./pages/services/sub-pages/verkaufsvermittlung";
import {WintercheckPage} from "./pages/services/sub-pages/wintercheck";
import {NewsPage} from "./pages/news";

import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {Jobs} from "./pages/jobs";

function ScrollToTop() {
    const pathName = useLocation();

    // This effect runs whenever the path name changes
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [pathName]);

    return null;
}

export const Router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop/>

            <Routes>
                {/*----------- main page resources ------------*/}
                <Route path="/" element={<Home/>}/>
                <Route path="/dienstleistungen" element={<Services/>}/>
                <Route path="/ueber-uns" element={<AboutUsPage/>}/>
                <Route path="/neuigkeiten" element={<NewsPage/>}/>
                <Route path="/neuigkeiten/:title" element={<NewsPage/>}/>
                <Route path="/team" element={<TeamPage/>}/>
                <Route path="/impressum" element={<Impressum/>}/>
                <Route path="/datenschutz" element={<Datenschutz/>}/>
                <Route path="/kontakt" element={<Contact/>}/>
                <Route path="/jobs" element={<Jobs/>}/>

                {/*----------- service resources ------------*/}
                <Route path="/achsvermessung" element={<AchsvermessungPage/>}/>
                <Route path="/auspuffservice" element={<AuspuffservicePage/>}/>
                <Route path="/bremsfluessigkeitswechsel" element={<BremsfluessigkeitswechselPage/>}/>
                <Route path="/elektroauto-service" element={<ElektroautoServicePage/>}/>
                <Route path="/fahrzeug-diagnose" element={<FahrzeugDiagnosePage/>}/>
                <Route path="/getriebespülung" element={<GetriebespuelungPage/>}/>
                <Route path="/hauptuntersuchung-abgasinspektion" element={<HauptuntersuchungAbgasinspektionPage/>}/>
                <Route path="/hol-und-bringdienst" element={<HolUndBringdienstPage/>}/>
                <Route path="/kfz-reparaturen-aller-fabrikate" element={<KfzReperaturnAllerFabrikatePage/>}/>
                <Route path="/klimaservice-klimaanlagen" element={<KlimaserviceKlimaanlagenPage/>}/>
                <Route path="/reifenservice" element={<ReifenservicePage/>}/>
                <Route path="/umweltplakette" element={<UmweltplakettePage/>}/>
                <Route path="/unfallinstandsetzung" element={<UnfallinstandsetungPage/>}/>
                <Route path="/verkaufsvermittlung" element={<VerkaufsvermittlungPage/>}/>
                <Route path="/wintercheck" element={<WintercheckPage/>}/>
            </Routes>
        </BrowserRouter>
    )
}