import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const KfzReperaturnAllerFabrikatePage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>KFZ-Reparaturen aller Fabrikate</h1>

                <div className={globalStyle.text_container}>
                    <h2>KFZ-Reparaturen aller Fabrikate</h2>
                    <p>Freie Werkstätten sind nicht nur eine günstige Alternative, sondern auch eine gute! Denn die
                        Mitarbeiter sind mindestens genauso kompetent, fachlich qualifiziert und hervorragend
                        ausgebildet
                        wie ihre Kollegen in den Vertragswerkstätten. Zudem geht es hier meist etwas persönlicher zu, so
                        dass Sie sich bestens aufgehoben fühlen. Aber überzeugen Sie sich am besten selbst.
                        Wichtig ist zunächst eine sorgfältige Fehlerdiagnose, denn manchmal sind es nur kleine Fehler
                        oder
                        Mängel, die mit wenigen Handgriffen repariert und beseitigt werden können. So ist eine Reparatur
                        oftmals kostengünstiger, als ein großflächiger und aufwendiger Austausch von Ersatzeilen.
                        Über unsere Partner beziehen wir die dabei benötigten Ersatzteile zu hervorragenden Konditionen
                        in
                        Erstausrüsterqualität, oder auch auf Wunsch eine günstige Alternative.

                        Bei uns ist Ihr Fahrzeug in guten Händen, egal welches Fabrikat.
                        Dabei haben wir im besonderen die Zeitwert-gerechte Reparatur von Gebrauchtwagen zu unserem
                        Steckenpferd gemacht.</p>
                </div>
            </div>


            <Footer/>
        </>
    )
}