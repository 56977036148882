import {IJob} from "../../../entities/job";
import styles from "./style.module.scss";

export const JobDetail = ({job}: { job: IJob}) => {
    return (
        <div className={styles.jobDetail}>
            <h2>{job.title}</h2>
            <p>{job.location} - {job.type}</p>
            {/*<button>Apply Now</button>*/}
            <div>
                <h3 className={styles.sectionHeader}>Stellenbeschreibung</h3>
                <p>{job.description}</p>
            </div>
            <div>
                {
                    job.skills.length > 0 && (
                        <div>
                            <h3 className={styles.sectionHeader}>Was du mitbringen solltest</h3>
                            <ul className={styles.skillList}>
                                {job.skills.map((skill, index) => (
                                    <li key={index}>{skill}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }
            </div>
            <div>
                {
                    job.tasks.length > 0 && (
                        <div>
                            <h3 className={styles.sectionHeader}>Deine Aufgaben bei uns</h3>
                            <ul className={styles.benefitList}>
                                {job.tasks.map((task, index) => (
                                    <li key={index}>{task}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }
            </div>
            <div>
                {
                    job.benefits.length > 0 && (
                        <div>
                            <h3 className={styles.sectionHeader}>Was wir bieten</h3>
                            <ul className={styles.benefitList}>
                                {job.benefits.map((benefit, index) => (
                                    <li key={index}>{benefit}</li>
                                ))}
                            </ul>
                        </div>
                    )
                }
            </div>
            <p className={styles.applyText}>
                Bewerbungen nehmen wir gern via E-Mail (<strong>{job.email}</strong>) entgegen oder wir verabreden uns
                telefonisch zum Kennenlernen.
            </p>
        </div>
    );
};
