import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const KlimaserviceKlimaanlagenPage = () => {
    return (
        <>
            <Header/>
            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Klimaservice (Klimaanlagen)</h1>

                <div className={globalStyle.text_container}>
                    <h2>Klima-Anlagen-Wartung</h2>
                    <p>Damit Sie nicht ins Schwitzen kommen! <br/>
                        Schon der jährliche Verlust von bis zu 10% Kältemittel kann die Funktion der Klimaanlage
                        einschränken oder zu Kompressorschäden führen. Sichern Sie sich die volle Kühlleistung und
                        machen
                        Sie den Klima-Check!</p>
                    <p>Wir prüfen die Klima-Anlage Ihres Fahrzeuges nicht nur auf Dichtigkeit und Funktion, sondern
                        recycelt
                        das Kältemittel, trocknen und befüllen die Anlage nach Herstellerangaben.</p>
                    <p>Lassen Sie die Hitze draußen mit Ihrer optimal gewarteten Klima-Anlage.</p>

                    <h2>Klima-Anlagen-Reinigung</h2>
                    <p>Lassen Sie die Klima-Anlage Ihres Fahrzeuges regelmäßig reinigen.</p>

                    <ul>
                        <li>Mindestens einmal pro Jahr oder alle 15.000 km sollte die Klima-Anlage gereinigt werden. Die
                            Reinigung befreit die Klima-Anlage von Bakterien, Pilzen, etc und sorgt für frische, saubere
                            Luft und optimale Kühlleistung.
                        </li>
                        <li>Der Innenraumfilter sollte jährlich gewechselt werden. Vor allem Allergiker leiden im
                            Frühjahr
                            unter den Blütenpollen von Gräsern und Bäumen. In Fahrzeugen mit regelmäßig ausgewechselten
                            Filtern sind sie deutlich besser geschützt.
                        </li>
                        <li>Der ideale Zeitpunkt ist im Frühjahr!</li>
                    </ul>
                </div>
            </div>


            <Footer/>
        </>
    )
}