import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import styles from "./style.module.scss";
import {useParams} from "react-router-dom";
import newsData from '../../assets/data/news.json';
import style from "../../components/home/news/style.module.scss";
import {INews} from "../../entities/news";
import {FullNewsPage} from "../../components/news/full-news-page";
import {NewsCard} from "../../components/news/news-card";
import {MainPost} from "../../components/news/main-post";

export const NewsPage = () => {
    const {title} = useParams();

    return (
        <div>
            <Header/>

            {
                title ? (
                    <div>
                        <FullNewsPage postTitle={title}/>
                    </div>
                ) : (
                    <div>
                        {
                            newsData.length > 0 && (
                                <MainPost
                                    key={newsData[0].id}
                                    image={newsData[0].image}
                                    title={newsData[0].title}
                                    description={newsData[0].description}
                                    date={newsData[0].date}
                                    category={newsData[0].category}
                                />
                            )
                        }

                        <div className={styles.lower_content}>
                            <h2>Aktuelle Neuigkeiten</h2>

                            <div className={styles.timeline}>
                                {
                                    newsData.filter((n: INews) => n.active).length > 0 ? (
                                        newsData
                                            .filter(n => n.active)
                                            .slice(0, 3)
                                            .map((news, index) => (
                                                <NewsCard
                                                    key={index}
                                                    image={news.image}
                                                    title={news.title}
                                                    description={news.description}
                                                    date={news.date}
                                                    category={news.category}
                                                />
                                            ))
                                    ) : (
                                        <div className={style.error_msg}>Keine Neuigkeiten</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            <Footer/>
        </div>
    );
};