import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const ReifenservicePage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Reifenservice</h1>

                <div className={globalStyle.text_container}>
                    <p>Als Marken-ungebundener Kfz-Meisterbetrieb erhalten Sie Hersteller-freie-Beratung.</p>

                    <ul>
                        <li>Montage am Fahrzeug</li>
                        <li>Auswuchten</li>
                        <li>Aufziehen</li>
                        <li>Beratung und Verkauf von Reifen, Felgen und Kompletträdern auch
                            mit RDKS (Reifendruckkontrollsystem)
                        </li>
                    </ul>
                </div>
            </div>


            <Footer/>
        </>
    )
}