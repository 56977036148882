import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCarBattery, faCarBurst, faGears, faSnowflake, faTags, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

export const Service = () => {
    const navigate = useNavigate();

    return (
        <div className={style.service_section}>
            <h2 className={style.section_title}>Unsere <span>Leistungen</span></h2>
            <p className={style.section_description}>
                In unserer Autowerkstatt bieten wir eine breite Palette an Dienstleistungen rund um Ihr Fahrzeug an.
                Von regelmäßigen Inspektionen und Ölwechseln über Reparaturen und Reifenwechsel bis hin zu
                Diagnosearbeiten und Karosserieinstandsetzungen – wir kümmern uns um alle Ihre Bedürfnisse, damit
                Ihr Auto stets in bestem Zustand bleibt.
            </p>
            <div className={style.services_grid_box}>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faCarBattery} className={style.icon}/>
                    <span>Batteriewechsel</span>
                </div>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faSnowflake} className={style.icon}/>
                    <span>Wintercheck</span>
                </div>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faGears} className={style.icon}/>
                    <span>Getriebespülung</span>
                </div>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faWrench} className={style.icon}/>
                    <span>KFZ-Reparaturen</span>
                </div>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faTags} className={style.icon}/>
                    <span>Umweltplakette</span>
                </div>
                <div className={style.service_grid_item}>
                    <FontAwesomeIcon icon={faCarBurst} className={style.icon}/>
                    <span>Unfallinstandsetzung</span>
                </div>
            </div>
            <button className={style.more_info_button} onClick={() => navigate("/dienstleistungen")}>
                <span>Alle Dienstleistungen...</span>
            </button>
        </div>
    )
}