import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import logo_banner from "../../../assets/images/logo.jpg";

export const ImportantInfoBanner = () => {
    return (
        <div>
            <img className={style.banner} src={logo_banner} alt='banner'/>

            <div className={style.important_info_container}>

                <div className={style.info_container}>
                    <FontAwesomeIcon icon={faPhone} className={style.icon}/>
                    <div className={style.text_container}>
                        <label>Rufen Sie jetzt an</label>
                        <p>+49 5128 - 1238</p>
                    </div>
                </div>

                <div className={style.info_container}>
                    <FontAwesomeIcon icon={faClock} className={style.icon}/>
                    <div className={style.text_container}>
                        <label>Geöffnet Montag bis Freitag</label>
                        <p>Mo. - Fr.: 8:00 - 17:00 Uhr</p>
                    </div>
                </div>

                <div className={style.info_container}>
                    <FontAwesomeIcon icon={faLocationDot} className={style.icon}/>
                    <div className={style.text_container}>
                        <label>Unsere Adresse</label>
                        <p>Kesselstraße 9 31249 Hohenhameln </p>
                    </div>
                </div>
            </div>
        </div>
    )
}