import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const GetriebespuelungPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Getriebespülung</h1>

                <div className={globalStyle.text_container}>
                    <p>Beugen sie Getriebeschäden jetzt an ihrem Fahrzeug vor!
                        Metallischer Abrieb an ihrem Automatikgetriebe kann große
                        Schäden verursachen. Wir wechseln das Getriebeöl incl.
                        Spülung des Systems an ihrem Schalt- und Automatikgetriebe</p>
                </div>
            </div>

            <Footer/>
        </>
    )
}