import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const UnfallinstandsetungPage = () => {
    return (
        <>
            <Header/>
            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Unfallinstandsetzung</h1>

                <div className={globalStyle.text_container}>
                    <p>Egal ob Schuld oder nicht Schuld, Unfälle sind immer ärgerlich. Aber wenn es schon passiert, dann
                        sollten Sie sich wenigstens sicher sein, dass Ihr Fahrzeug ohne Mängel fachgerecht und kompetent
                        repariert wird. Gern unterstützen wir auch bei der Abwicklung von Unfallschäden
                    </p>

                    <ul>
                        <li>direkte Abrechnung bei unstrittigen Schäden mit der Versicherung</li>
                        <li>Unterstützung bei sämtlichen Verwaltungsvorgängen mit der Versicherung</li>
                        <li>Geltendmachung einfacher und unstreitiger Ansprüche, z. B. die Abrechnung von
                            Unfallersatzansprüchen
                        </li>
                        <li>Wir setzen Ihr Fahrzeug nach einem Schaden fach- und sachgerecht nach Herstellervorgaben
                            instand.
                        </li>
                    </ul>
                </div>
            </div>

            <Footer/>
        </>
    )
}