import style from './style.module.scss'
import {Header} from "../../components/header"
import {ImportantInfoBanner} from "../../components/home/important-info-banner";
import {Service} from "../../components/home/service";
import {Team} from "../../components/home/team";
import {AboutUs} from "../../components/home/about-us";
import {Footer} from "../../components/footer";
import {Popup} from "../../components/popup";
import {useEffect, useState} from "react";
import {News} from "../../components/home/news";

export const Home = () => {

    const activeHoliday: boolean = false;

    const [isHoliday, setIsHoliday] = useState(true);
    useEffect(() => {
        setIsHoliday(!JSON.parse(localStorage.getItem('isHolidayPopupAccepted') || "false"))
    }, []);

    function holidayPopup() {
        localStorage.setItem('isHolidayPopupAccepted', 'true');
    }

    return (
        <div className={style.home_section}>
            <Header/>

            {
                (isHoliday && activeHoliday) && (
                    <Popup title={"Urlaub"} message={"Wir sind bis zum 18.7.24 im Urlaub"} isShown={isHoliday}
                           action={holidayPopup}/>
                )
            }

            <ImportantInfoBanner/>

            <h1>Karl Räke Kraftfahrzeuge e.K. - Kfz Werkstatt Hohenhameln</h1>

            <AboutUs/>

            <Team/>

            <Service/>

            <News/>

            <Footer/>
        </div>
    )
}