import styles from "./style.module.scss";
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";

export const Datenschutz = () => {
    return (
        <div>
            <Header/>
            <div className={styles.data_protection_section}>
                <h1>Datenschutzerklärung</h1>

                <p className={styles.headline_1}>Datenschutz</p>
                <p className={styles.content_block}>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
                    sehr ernst. Wir behandeln Ihre
                    personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                    dieser Datenschutzerklärung.
                </p>
                <p className={styles.content_block}>
                    Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
                    unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben
                    werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                    ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                </p>
                <p className={styles.content_block}>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                    nicht möglich.
                </p>

                <p className={styles.headline_1}>Cookies</p>
                <p className={styles.content_block}>
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen
                    Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher,
                    effektiver
                    und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
                    die
                    Ihr Browser speichert.
                </p>

                <p className={styles.content_block}>
                    Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende
                    Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie
                    diese
                    löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                </p>

                <p className={styles.content_block}>
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
                    Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                    ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei
                    der
                    Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                </p>

                <p className={styles.headline_1}>Server-Log-Files</p>
                <p className={styles.content_block}>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log
                    Files,
                    die Ihr Browser automatisch an uns übermittelt. Dies sind:
                </p>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                </ul>
                <p className={styles.content_block}>
                    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine
                    Zusammenführung dieser Daten mit anderen
                    Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn
                    uns
                    konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                </p>

                <p className={styles.headline_1}>Kontaktformular</p>
                <p className={styles.content_block}>
                    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                    Anfrageformular
                    inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
                    Fall
                    von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
                </p>

                <p className={styles.headline_1}>Google Analytics</p>
                <p className={styles.content_block}>
                    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google
                    Inc.,
                    1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
                </p>
                <p className={styles.content_block}>
                    Google Analytics verwendet so genannte “Cookies”. Das sind Textdateien, die auf Ihrem Computer
                    gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
                    den
                    Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen
                    Server
                    von Google in den USA übertragen und dort gespeichert.
                </p>

                <p className={styles.headline_1}>Browser Plugin</p>
                <p className={styles.content_block}>
                    Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
                    verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                    Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die
                    Erfassung
                    der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer
                    IPAdresse)
                    an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
                    folgenden
                    Link verfügbare Browser-Plugin herunterladen und installieren:
                    https://tools.google.com/dlpage/gaoptout?hl=de
                </p>

                <p className={styles.headline_1}>Widerspruch gegen Datenerfassung</p>
                <p className={styles.content_block}>
                    Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
                    klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen
                    dieser Website verhindert: Google Analytics deaktivieren
                </p>
                <p className={styles.content_block}>
                    Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
                    finden Sie in der
                    Datenschutzerklärung von Google:https://support.google.com/analytics/answer/6004245?hl=de
                </p>
            </div>

            <Footer/>
        </div>
    )
}