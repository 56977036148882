import {IJob} from "../../../entities/job";
import styles from "./style.module.scss";

export const JobList = ({jobs, onJobSelect}: {
    jobs: IJob[],
    onJobSelect: React.Dispatch<React.SetStateAction<IJob | undefined>>
}) => {
    return (
        <div className={styles.jobList}>
            {jobs.map((job) => (
                <div
                    key={job.id}
                    className={styles.jobCard}
                    onClick={() => onJobSelect(job)}
                >
                    <h3>{job.title}</h3>
                    <p>{job.location}</p>
                    <p>{job.datePosted}</p>
                </div>
            ))}
        </div>
    );
};
