import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from '../style.module.scss';
import {Footer} from "../../../../components/footer";

export const WintercheckPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Wintercheck</h1>

                <div className={globalStyle.text_container}>
                    <p>Es ist immer der richtige Zeitpunkt für unseren umfangreichen Winter-Check. Wir sorgen dafür,
                        dass
                        Ihr Auto schnell fit für den Winter wird und machen es startklar für frostige Temperaturen.
                        Dabei
                        überprüfen wir für Sie Reifendruck und -profil, Bremsen, Bremssystem, Bremsflüssigkeit,
                        Scheibenwaschanlage, Stossdämpfer, Motorölstand, Batterie, Keilriemen und Wischerblätter.
                    </p>
                </div>
            </div>

            <Footer/>
        </>
    )
}