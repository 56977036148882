import {Header} from "../../components/header";
import {Footer} from "../../components/footer";
import {useNavigate} from "react-router-dom";
import styles from "./style.module.scss";

export const Services = () => {

    return (
        <div className={styles.services}>
            <Header/>
            <h1 className={styles.section_title}>Alle unsere <span>Dienstleistungen</span></h1>

            <div className={styles.service_list}>
                {pageUrls.length > 0 && pageUrls.map((url) => (
                    <ServiceCard title={url.name} description={url.description} route={url.url}/>
                ))}
            </div>

            <Footer/>
        </div>
    );
};

const ServiceCard = (
    {
        title,
        description,
        route
    }: {
        title: string,
        description: string,
        route: string
    }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.service_card}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
            <div className={styles.button_container}>
                <button className={styles.read_more_button} onClick={() => navigate(`${route}`)}>
                    <span>Mehr Erfahren...</span>
                </button>
            </div>
        </div>
    )
}

export const pageUrls = [
    {
        "name": "Achsvermessung",
        "url": "/achsvermessung",
        "description": "Präzise Einstellung der Fahrzeugachse für bessere Fahrsicherheit und Reifenverschleiß."
    },
    {
        "name": "Auspuffservice",
        "url": "/auspuffservice",
        "description": "Wartung und Reparatur des Abgassystems für optimale Leistung und Emissionskontrolle."
    },
    {
        "name": "Bremsflüssigkeitswechsel",
        "url": "/bremsfluessigkeitswechsel",
        "description": "Erneuerung der Bremsflüssigkeit zur Sicherstellung der Bremsleistung."
    },
    {
        "name": "Elektroauto Service",
        "url": "/elektroauto-service",
        "description": "Spezialisierte Wartung und Reparatur für Elektrofahrzeuge."
    },
    {
        "name": "Fahrzeug – Diagnose",
        "url": "/fahrzeug-diagnose",
        "description": "Umfassende Fehlerdiagnose zur Identifikation und Behebung von Problemen."
    },
    {
        "name": "Hauptuntersuchung / Abgasinspektion",
        "url": "/hauptuntersuchung-abgasinspektion",
        "description": "Regelmäßige Inspektion zur Sicherstellung der Straßenverkehrsordnung und Emissionsvorschriften."
    },
    {
        "name": "Getriebespülung",
        "url": "/getriebespuelung",
        "description": "Reinigung des Getriebesystems zur Verbesserung der Schaltleistung und Verlängerung der Lebensdauer."
    },
    {
        "name": "Hol- und Bringdienst",
        "url": "/hol-und-bringdienst",
        "description": "Abholung und Rückgabe Ihres Fahrzeugs für eine bequeme Wartung."
    },
    {
        "name": "KFZ-Reparaturen aller Fabrikate",
        "url": "/kfz-reparaturen-aller-fabrikate",
        "description": "Reparatur- und Wartungsservice für alle Fahrzeugmarken und -modelle."
    },
    {
        "name": "Klimaservice (Klimaanlagen)",
        "url": "/klimaservice-klimaanlagen",
        "description": "Wartung und Reparatur der Klimaanlage für angenehmes Fahrklima."
    },
    {
        "name": "Reifenservice",
        "url": "/reifenservice",
        "description": "Montage, Wuchten und Reparatur von Reifen für sichere Fahrten."
    },
    {
        "name": "Umweltplakette",
        "url": "/umweltplakette",
        "description": "Ausstellung der Umweltplakette für die Einhaltung der Abgasnormen."
    },
    {
        "name": "Unfallinstandsetzung",
        "url": "/unfallinstandsetzung",
        "description": "Fachgerechte Reparatur von Fahrzeugschäden nach einem Unfall."
    },
    {
        "name": "Verkaufsvermittlung",
        "url": "/verkaufsvermittlung",
        "description": "Unterstützung beim Verkauf Ihres Fahrzeugs durch professionelle Vermittlung."
    },
    {
        "name": "Wintercheck",
        "url": "/wintercheck",
        "description": "Überprüfung aller wichtigen Fahrzeugkomponenten zur Sicherstellung der Wintertauglichkeit."
    }
]
