import style from "./style.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from 'react-burger-menu';
import './style.scss';
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo_mitte.png"

export const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();
    const handleStateChange = (state: { isOpen: boolean }) => {
        setMenuOpen(state.isOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <header className={style.header_section}>
            <nav className={style.desktop_nav}>
                <div className={style.left_container}>
                    <ul className={style.control_container}>
                        <li><Link className={style.menu_item} to={"/"}>Startseite</Link></li>
                        <li><Link className={style.menu_item} to={"/neuigkeiten"}>Neuigkeiten</Link></li>
                        <li><Link className={style.menu_item} to={"/dienstleistungen"}>Dienstleistungen</Link></li>
                        <li><Link className={style.menu_item} to={"/jobs"}>Jobs</Link></li>
                        <li><Link className={style.menu_item} to={"/ueber-uns"}>Über uns</Link></li>
                    </ul>
                </div>

                <div className={style.controls}>
                    {/*<div className={style.search_bar_container}>*/}
                    {/*    <input className={style.search_bar} placeholder="Suchen..."/>*/}
                    {/*    <FontAwesomeIcon icon={faMagnifyingGlass} className={style.search_icon}/>*/}
                    {/*</div>*/}

                    <div className={style.round_contact_button} onClick={() => navigate("/kontakt")}>
                        <FontAwesomeIcon icon={faPhone}/>
                    </div>
                </div>
            </nav>

            <div className={style.mobile_nav}>
                <div className={`navbar ${menuOpen ? 'menu-open' : ''}`}>
                    {/* Company Logo */}
                    <div className={style.logo_container}>
                        <Link to="/" onClick={closeMenu}>
                            <img
                                src={logo}
                                alt="Company Logo"
                                className={style.logo}
                            />
                        </Link>
                    </div>

                    <Menu right isOpen={menuOpen} onStateChange={handleStateChange}>
                        <ul className={style.control_container}>
                            <li>
                                <Link className={style.menu_item} to="/" onClick={closeMenu}>
                                    Startseite
                                </Link>
                            </li>
                            <li>
                                <Link className={style.menu_item} to="/neuigkeiten" onClick={closeMenu}>
                                    Neuigkeiten
                                </Link>
                            </li>
                            <li>
                                <Link className={style.menu_item} to="/dienstleistungen" onClick={closeMenu}>
                                    Dienstleistungen
                                </Link>
                            </li>
                            <li>
                                <Link className={style.menu_item} to="/kontakt" onClick={closeMenu}>
                                    Kontakt
                                </Link>
                            </li>
                            <li>
                                <Link className={style.menu_item} to="/jobs" onClick={closeMenu}>
                                    Jobs
                                </Link>
                            </li>
                            <li>
                                <Link className={style.menu_item} to="/kontakt" onClick={closeMenu}>
                                    Kontakt
                                </Link>
                            </li>
                        </ul>
                    </Menu>
                </div>
            </div>
        </header>
    )
}

