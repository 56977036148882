import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const HauptuntersuchungAbgasinspektionPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Hauptuntersuchung / Abgasinspektion</h1>

                <div className={globalStyle.text_container}>
                    <p>Regelmäßig findet in unserem Hause die Abnahme der Hauptuntersuchung durch die Dekra statt.
                        Gern bereiten wir Ihr Fahrzeug für diese Untersuchung vor und stellen es dem Dipl. Ingenieur der
                        Dekra zur Abnahme vor.</p>
                </div>
            </div>

            <Footer/>
        </>
    )
}