import {Team} from "../../components/home/team";
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";

export const TeamPage = () => {
    return (
        <>
            <Header/>
            <Team/>
            <Footer/>
        </>
    )
}