import style from "./style.module.scss";
import historischesBild from "../../../assets/images/historisches-bild-karl-raeke-kfz-werkstatt-peine-hildesheim.jpg"
import { useNavigate } from "react-router-dom";

export const AboutUs = () => {
    const navigate = useNavigate();
    return (
        <div className={style.about_us_section}>
            <h2 className={style.section_title}>Über <span>Uns</span></h2>

            <div className={style.content_container}>
                <div className={style.img_container}>
                    <img src={historischesBild} alt="historisches bild karl raeke kfz werkstatt"/>

                    <div className={style.background}/>
                </div>


                <div className={style.text_container}>
                    <p className={style.headline}>Karl Räke Kraftfahrzeuge – Familienbetrieb in 3. Generation!</p>

                    <p className={style.info_content}>
                        Seit vielen Jahren ist Karsten Belte als Werkstattmeister in
                        unserem Unternehmen tätig. Bereits
                        seine Ausbildung zum Kfz-Techniker absolvierte er bei der Karl Räke Kraftfahrzeuge. Dort lernte
                        er sowohl von dem „Altmeister“ Karl Räke „alte Handwerkskunst“, als auch von Klaus Wunram neue
                        Kraftfahrzeugmechatronik. Durch persönliches Interesse, Engagement und Weiterbildung ist er mit
                        innovativer Fahrzeugtechnik bestens vertraut.
                    </p>

                    <div className={style.button_container}>
                        <button className={style.more_button} onClick={() => navigate("/ueber-uns")}>Mehr Erfahren...</button>
                    </div>
                </div>
            </div>
        </div>
    )
}