import style from "./style.module.scss";
import employee_1 from "../../../assets/images/employees/IMG_0354_kl.jpg";
import employee_2 from "../../../assets/images/employees/menzel.jpg";
import employee_3 from "../../../assets/images/employees/IMG_0380_kl.jpg"
import employee_4 from "../../../assets/images/employees/IMG_0340_kl.jpg";
import employee_5 from "../../../assets/images/employees/IMG_0348_kl.jpg";

export const Team = () => {
    return (
        <div className={style.team_section}>
            <h2 className={style.section_title}>Unser <span>Team</span></h2>
            <p className={style.section_description}>Da unsere Techniker an vielen Marken arbeiten, können sie die
                verschiedenen Reparaturen zuverlässig
                diagnostizieren</p>

            <div className={style.team_content}>
                {/*<img className={style.arrow_image} src={arrows} alt=""/>*/}

                {/*<div className={style.buttons}>*/}
                {/*    <button className={style.team_button}>*/}
                {/*        Werkstatt*/}
                {/*    </button>*/}

                {/*    <button className={style.team_button}>*/}
                {/*        Kontakt und Buchhaltung*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>

            <div className={style.employee_grid}>
                <div className={style.employee_item} style={{backgroundImage: `url(${employee_1})`}}>
                    <div className={style.employee_info}>
                        <p className={style.name}>Karsten Belte</p>
                        <p className={style.role}>Werkstattleiter und Inhaber</p>
                    </div>
                </div>

                <div className={style.employee_item} style={{backgroundImage: `url(${employee_2})`}}>
                    <div className={style.employee_info}>
                        <p className={style.name}>Luis Menzel</p>
                        <p className={style.role}>KFZ-Mechatroniker</p>
                    </div>
                </div>

                <div className={style.employee_item} style={{backgroundImage: `url(${employee_3})`}}>
                    <div className={style.employee_info}>
                        <p className={style.name}>Klaus Wunram</p>
                        <p className={style.role}>Seniorchef</p>
                    </div>
                </div>

                <div className={style.employee_item} style={{backgroundImage: `url(${employee_4})`}}>
                    <div className={style.employee_info}>
                        <p className={style.name}>Jana Belte</p>
                        <p className={style.role}>Verwaltung, Buchhaltung, Kontakt</p>
                    </div>
                </div>

                <div className={style.employee_item} style={{backgroundImage: `url(${employee_5})`}}>
                    <div className={style.employee_info}>
                        <p className={style.name}>Gertrud Wunram</p>
                        <p className={style.role}>Verwaltung, Kontakt</p>
                    </div>
                </div>
            </div>
        </div>
    )
}