import logo from '../../assets/images/Karl Räke Kraftfahrzeuge.svg';
import style from "./style.module.scss";
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <footer>
            <div className={style.upper_footer}>
                <div className={style.left_side}>
                    <img src={logo} alt="logo" className={style.icon}/>

                    <p className={style.company_description}>
                        Karl Räke Kraftfahrzeuge bietet Ihnen umfassenden Service und Expertise für Ihr Auto. Egal ob
                        Inspektion, Reparatur oder Wartung – bei uns sind Sie in besten Händen. Entdecken Sie unsere
                        vielfältigen Dienstleistungen und finden Sie genau das, was Ihr Fahrzeug braucht!
                    </p>
                </div>

                <div className={style.right_side}>
                    <div className={style.footer_info_container}>
                        <h3>Öffnungszeiten</h3>
                        <ul>
                            <li>Montag: 8:00 - 17:00 Uhr</li>
                            <li>Dienstag: 8:00 - 17:00 Uhr</li>
                            <li>Mittwoch: 8:00 - 17:00 Uhr</li>
                            <li>Donnerstag: 8:00 - 17:00 Uhr</li>
                            <li>Freitag: 8:00 - 17:00 Uhr</li>
                            <li>Samstag: Geschlossen</li>
                            <li>Sontag: Geschlossen</li>
                        </ul>
                    </div>

                    <div className={style.footer_info_container}>
                        <h3>Rechtliches</h3>
                        <ul>
                            <li><Link to={"/impressum"} className={style.link}><span>Impressum</span></Link></li>
                            <li><Link to={"/datenschutz"} className={style.link}><span>Datenschutz</span></Link></li>
                        </ul>
                    </div>

                    <div className={style.footer_info_container}>
                        <h3>Unser Unternehmen</h3>
                        <ul>
                            <li><Link to={"/ueber-uns"} className={style.link}><span>Über uns</span></Link></li>
                            <li><Link to={"/team"} className={style.link}><span>Unser Team</span></Link></li>
                            <li><Link to={"/kontakt"} className={style.link}><span>Kontakt</span></Link></li>
                            <li><Link to={"/neuigkeiten"} className={style.link}><span>Neuigkeiten</span></Link></li>
                            <li><Link to={"/jobs"} className={style.link}><span>Jobs</span></Link></li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className={style.divider_container}>
                <div className={style.divider}/>
            </div>

            <div className={style.lower_footer}>
                <p>© 2024 Karl Räke Kraftfahrzeuge. All Rights Reserved.</p>
                <Link className={style.link} to={"/datenschutz"}><p>Datenschutz und Cookies</p></Link>
            </div>
        </footer>
    )
}