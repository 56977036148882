import styles from './style.module.scss';
import {Header} from "../../components/header";
import {Footer} from "../../components/footer";

export const Impressum = () => {
    return (
        <div>
            <Header/>

            <div className={styles.imprint_sections}>
                <h1>Impressum</h1>

                <p className={styles.headline_1}>Angaben gemäß § 5 TMG:</p>
                <p>
                    Karsten Belt <br/>
                    Karl Räke Kraftfahrzeuge e.K.<br/>
                    Kesselstraße 9<br/>
                    31249 Hohenhameln / Bierbergen<br/>
                </p>

                <p className={styles.headline_1}>Kontakt:</p>
                <p>
                    Telefon: 05128/1238<br/>
                    Telefax: 05128/1055<br/>
                    E-Mail: raekekfz@web.de<br/>
                </p>

                <p className={styles.headline_1}>Umsatzsteuer-ID:</p>
                <p className={styles.headline_2}>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</p>
                <p className={styles.content_block}>DE 276 357 283</p>

                <p className={styles.headline_1}>Handelsregister</p>
                <p className={styles.content_block}>HRA100674</p>

                <p className={styles.headline_1}>Angaben zur Berufshaftpflichtversicherung:</p>
                <p className={styles.headline_2}>Name und Sitz der Gesellschaft:</p>
                <p className={styles.content_block}>
                    VGH Versicherungen<br/>
                    Schiffgraben 4<br/>
                    30159 Hannover <br/>
                    Geltungsraum der Versicherung: Deutschland
                </p>

                <p className={styles.headline_1}>Haftungsausschluss (Disclaimer)</p>

                <p className={styles.headline_2}>Haftung für Inhalte</p>
                <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                    allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen
                    zu
                    forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                    Sperrung
                    der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung
                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                    entfernen.
                </p>

                <p className={styles.headline_2}>Haftung für Links</p>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss
                    haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                    verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                    Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                    inhaltliche
                    Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                    zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>

                <p className={styles.headline_2}>Urheberrecht</p>
                <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                    trotzdem
                    auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
            </div>

            <Footer/>
        </div>
    )
}