import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const AchsvermessungPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Achsvermessung</h1>

                <div className={globalStyle.text_container}>
                    <p>Kleiner Rempler, großer Schaden? Ungleichmäßiges Abfahren des Reifenprofils?
                        Das Fahrwerk Ihres Fahrzeuges verzeiht nicht. Eine mangelhafte Achsgeometrie kommt häufiger vor
                        als
                        Sie
                        denken.</p>

                    <p>Korrekt eingestellte Spur, Sturz und Nachlaufwerte sind entscheidet für das Fahrverhalten und
                        -eigenschaften Ihres Fahrzeuges – und damit für Ihre Sicherheit!</p>

                    <p>Mit einem neusten Lasersystem können wir bei PKW, Transporter und Geländewagen aller
                        Fahrzeughersteller
                        bis zu einer Felgengröße von 22″ schnell und zuverlässig Spur, Sturz, Spurdifferenzwinkel,
                        Nachlauf,
                        Spreizung, Lenkradmittelstand und Achsschrägstand messen, Abweichungen feststellen und ggf.
                        einstellen/instandsetzen.</p>

                    <p>Einen Ausdruck des Messprotokolls erhalten Sie auf Wunsch selbstverständlich.</p>
                </div>
            </div>

            <Footer/>
        </>
    )
}