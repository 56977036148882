import style from './style.module.scss';
import {useNavigate} from "react-router-dom";
import newsData from '../../../assets/data/news.json';
import {MainPost} from "../../news/main-post";

export const News = () => {
    const navigate = useNavigate();

    return (
        <div className={style.news_section}>

            <h2 className={style.section_title}>Aktuelle <span>Neuigkeiten</span></h2>

            <div className={style.post_container}>
                <MainPost
                    key={newsData[0].id}
                    image={newsData[0].image}
                    title={newsData[0].title}
                    description={newsData[0].description}
                    date={newsData[0].date}
                    category={newsData[0].category}
                />
            </div>

            <button className={style.more_info_button} onClick={() => navigate("/neuigkeiten")}>
                <span>Mehr Neuigkeiten...</span>
            </button>
        </div>
    );
}