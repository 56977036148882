import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const FahrzeugDiagnosePage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Fahrzeug – Diagnose</h1>

                <div className={globalStyle.text_container}>
                    <p>Um auch für die neuesten Modelle und eine ausgereifte Fehlerdiagnose gerüstet zu sein, halten wir
                        für
                        Sie stets aktuelle Diagnosegeräte bereit. Ebenso wie Hersteller-Vertragspartner sind wir mittels
                        dieser Technik in der Lage, die entsprechenden Fehler zu diagnostizieren und instand zu
                        setzen.</p>
                </div>
            </div>


            <Footer/>
        </>
    )
}