import {Header} from "../../components/header";
import historischesBild from "../../assets/images/historisches-bild-karl-raeke-kfz-werkstatt-peine-hildesheim.jpg";
import style from './style.module.scss';
import {Footer} from "../../components/footer";

export const AboutUsPage = () => {
    return (
        <>
            <Header/>

            <div className={style.content_section}>
                <h1 className={style.section_title}>Über <span>Uns</span></h1>

                <div className={style.text_section}>
                    <div className={style.wrap}>
                        <div className={style.img_container}>
                            <img src={historischesBild} alt="historisches bild karl raeke kfz werkstatt"/>
                            <div className={style.background}/>
                        </div>
                    </div>

                    <div className={style.text}>
                        <p className={style.headline}>
                            Karl Räke Kraftfahrzeuge – Familienbetrieb in 3. Generation!
                        </p>

                        <p></p>
                        <p>
                            Die Kfz-Werkstatt wurde 1949 von Herrn Karl Räke und seiner Ehefrau Irmchen in
                            Bierbergen Kesselstraße 11, gegründet.
                            <br/>
                        </p>

                        <p>
                            Den damaligen Bedürfnissen angepasst wurden neben Kraftfahrzeugen auch Nähmaschinen,
                            Fahrräder, Motorräder und Landmaschinen verkauft und instandgesetzt.
                        </p>
                        <br/>

                        <p>
                            Im Laufe der Jahre verlagerte sich der Schwerpunkt auf den Handel, sowie die
                            Kraftfahrzeug-
                            Instandhaltung/-setzung von Fahrzeugen aller Fabrikate!
                        </p>
                        <br/>

                        <p>
                            Von 1986 wurde das Unternehmen von Kfz-Meister Klaus Wunram und seiner Ehefrau Gertrud, geb.
                            Räke weitergeführt. Seit März 2011 wird der Betrieb von Karsten Belte und Jana Belte, geb.
                            Wunram,geleitet.
                        </p>
                        <br/>

                        <p>
                            Seit vielen Jahren ist Karsten Belte als Werkstattmeister in unserem Unternehmen tätig.<br/>
                            Bereits seine Ausbildung zum Kfz-Techniker absolvierte er bei der Karl Räke Kraftfahrzeuge.
                            Dort lernte er sowohl von dem „Altmeister“ Karl Räke „alte Handwerkskunst“, als auch von
                            Klaus Wunram neue Kraftfahrzeugmechatronik. Durch persönliches Interesse, Engagement und
                            Weiterbildung ist er mit innovativer Fahrzeugtechnik bestens vertraut.
                        </p>
                        <br/>

                        <p>Mitglied der KFZ-Innung Peine<br/>
                            Eingetragener KFZ-Handwerksbetrieb bei der Handwerkskammer Braunschweig
                        </p>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}