import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const ElektroautoServicePage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Elektroauto Service
                </h1>

                <div className={globalStyle.text_container}>
                    <p>Jetzt auch bei uns: Wartungen und Reparaturen an Elektrofahrzeugen</p>
                </div>
            </div>

            <Footer/>
        </>
    )
}