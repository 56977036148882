import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const VerkaufsvermittlungPage = () => {
    return (
        <>
            <Header/>
            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Verkaufsvermittlung</h1>

                <div className={globalStyle.text_container}>
                    <p></p>
                </div>
            </div>

            <Footer/>
        </>
    )
}