import {Header} from "../../../../components/header";
// @ts-ignore
import globalStyle from "../style.module.scss";
import {Footer} from "../../../../components/footer";

export const HolUndBringdienstPage = () => {
    return (
        <>
            <Header/>

            <div className={globalStyle.detail_service}>
                <h1 className={globalStyle.headline}>Hol- und Bringdienst</h1>

                <div className={globalStyle.text_container}>
                    <p>Als besonderen Service bieten wir Ihnen auch einen Hol- und Bringdienst. So verlieren Sie keine
                        Zeit
                        und ihr Fahrzeug kann nach Wunsch zu Untersuchungen oder Reparaturen geholt und wieder
                        zurückgebracht werden.</p>
                </div>
            </div>

            <Footer/>
        </>
    )
}