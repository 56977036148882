import './style.scss'
import {Router} from "./router";
import {CookieConsent} from "react-cookie-consent";


export function App() {
    return (
        <>
            <Router/>

            <CookieConsent
                location="bottom"
                buttonText="Verstanden"
                cookieName="cookieConsent"
                style={{
                    background: "#232323",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                buttonStyle={{
                    color: "#fff",
                    fontSize: "18px",
                    background: "var(--orange)",
                    borderRadius: "15px",
                    padding: "10px 20px"
                }}
                expires={150}
            >
                Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu verbessern.{" "}
            </CookieConsent>
        </>
    )
}
